import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../../components/headings'
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  styled,
  Typography,
} from '@mui/material'
import '../../../../styles/components/_layouts.scss'
import '../../../../styles/components/_grids.scss'
import '../../../../styles/components/_box.scss'
import '../../../../styles/components/_inputs.scss'
import {
  ButtonInputUpload,
  TextInputClassic,
} from '../../../../components/inputs'
import TextareaInputClassic from '../../../../components/inputs/TextareaInputClassic'
import {
  BorderButtonPurple,
  CustomButton,
} from '../../../../components/buttons'
import { useNavigate, useParams } from 'react-router-dom'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../styles/theme/lightTheme'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import {
  SOFTSKILLS,
  countries,
  disponibilitesList,
  disponibleSousList,
  environnementsTechniques,
  statusMaritalList,
  typeProfilList,
} from '../../../../data'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  /*  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8), */
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const schema = yup.object({
  prenom: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  nom: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  email: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .email('Merci de rentrer un email valide.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  telephone: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  commentaire: yup
    .string()
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  /*.matches(phoneRegExp, "Merci de rentrer un numéro de téléphone valide.")*/
  /* genre: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  codePostal: yup
    .number()
    .typeError('Veuillez respecter le format.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  dateNaissance: yup.date('Ce champ doit être une date valide.'), 
  job: yup.string('Ce champ doit être une chaîne de caractères.'),*/
  cv: yup.mixed(),
})

const AddCandidate = () => {
  const { t } = useTranslation()
  let navigate = useNavigate()
  const userData = useSelector((state) => state.userAuth.user)
  const [loadingAddAdmin, setLoadingAddAdmin] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingCommerc, setLoadingCommerc] = useState(false)
  const [errorsJob, setErrorsJob] = useState(false)
  const [errorsStatusMarital, setErrorsStatusMarital] = useState(false)
  const [errorsTypeProfil, setErrorsTypeProfill] = useState(false)
  const [errorsCommercial, setErrorsCommercial] = useState(false)
  const [errorFiles, setErrorFiles] = useState(false)
  const [errorCompetences, setErrorCompetences] = useState(false)
  const [errorCompetencesAnnexe, setErrorCompetencesAnnexe] = useState(false)
  const [phoneValue, setPhoneValue] = useState()
  const [file, setFile] = useState('')
  const [statusMarital, setStatusMarital] = useState('')
  const [statusMaritalCommercial, setStatusMaritalCommercial] = useState('')
  const [typeProfil, setTypeProfil] = useState('')
  const [dateValue, setDateValue] = useState(dayjs(new Date('01/01/2000')))
  const [postes, setPostes] = useState([])
  const [posteSelect, setPosteSelect] = useState('')
  const [commercials, setCommercials] = useState([])
  const [commercialSelect, setCommercialSelect] = useState([])
  const [open, setOpen] = React.useState(false)
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms (5 seconds)
  const [competences, setCompetences] = useState([])
  const [competencesAnnexe, setCompetencesAnnexe] = useState([])
  const [softskils, setSoftskils] = useState([])
  const [valeurs, setValeurs] = useState([])
  const [selectedCountry, setSelectedCountry] = useState({
    label: 'France',
  })
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState('Paris')

  const [dateChange, setDateChange] = useState(false)
  const [disponibilitesSelect, setDisponibilitesSelect] = useState(
    disponibilitesList[0]
  )
  const [disponibleSousSelect, setDisponibleSousSelect] = useState(
    disponibleSousList[0]
  )
  const [dateDisponibleValue, setDateDisponibleValue] = useState(
    dayjs(new Date())
  )
  const [competenceOpen, setCompetenceOpen] = useState(false)
  const [competenceAnnexeOpen, setCompetenceAnnexeOpen] = useState(false)
  const [loadingCompetence, setLoadingCompetence] = useState(false)
  const [environnements, setEnvironnements] = useState([])

  const changeDate = (dateValue) => {
    setDateValue(dateValue)
    setDateChange(true)
  }
  const changeDateDisponible = (dateValue) => {
    setDateDisponibleValue(dateValue)
  }

  const params = useParams()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    handleCountryChange(selectedCountry)
  }, ['selectedCountry'])

  const handleCountryChange = async (country) => {
    setSelectedCountry(country)

    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        //setSelectedCity(res.data.data[0])
      }
    } catch (error) {
      console.bebug(error)
    }
  }

  useEffect(() => {
    getPostes()
    getEnvironnements()
    getCommerciaux()
  }, [])

  const getCommerciaux = async () => {
    setLoadingCommerc(true)
    try {
      let result = await Api.user.listWithFilter({
        role: [
          'ROLE_COMMERCIAL',
          'ROLE_RECRUTEUR',
          'ROLE_ADMIN',
          'ROLE_SUPER_ADMIN',
        ],
        status: 'active',
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingCommerc(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoadingCommerc(false)
        setCommercials(res)
        setCommercialSelect([res.find((user) => user.id === userData.data.id)])
      } else {
        setLoadingCommerc(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingCommerc(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const getPostes = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoading(true)

    try {
      const result = await Api.poste.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setPostes(res.data)
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const getEnvironnements = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingCompetence(true)

    try {
      const result = await Api.environnement.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setEnvironnements(res.data)
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))

        /*  if (besoin) {
          setPosteSelect(besoin.poste)
        } else {
          setPosteSelect(res.data[0])
        } */
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingCompetence(false)
  }

  const handleStatusMaritalSelect = (event) => {
    const inputValue = event.target.value
    setStatusMarital(inputValue)
  }

  const addCandidate = async (data) => {
    //console.log(data);return
    setLoadingAddAdmin(true)
    try {
      const result = await Api.candidate.addPlusCv(data)
      const res = handlingErrors(result)
      if (res.ok) {
        toast.success('La création du candidat à été réalisé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })

        setTimeout(() => {
          params.type === 'client'
            ? navigate('/admin/gestion-client/liste-clients-candidats')
            : navigate('/admin/gestion-prospect/liste-prospects-candidats')
        }, 1000)
      }

      if (res.status === 422) {
        toast.error("L'email est déjà utilisé pour un autre compte !", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (res.status === 401) {
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingAddAdmin(false)
  }

  const handleFileChange = (event) => {
    setFile(event.target.files)
    setValue('files', [event.target.files])
  }

  const onSubmit = (data) => {
    console.log(
      disponibilitesSelect.id,
      disponibleSousSelect.id,
      dateDisponibleValue,
      commercialSelect.length
    )

    //return

    setErrorsJob(false)
    setErrorsStatusMarital(false)
    setErrorsTypeProfill(false)
    setErrorsCommercial(false)
    setErrorFiles(false)
    setErrorCompetences(false)
    setErrorCompetencesAnnexe(false)

    if (!posteSelect) setErrorsJob(true)
    if (!statusMarital) setErrorsStatusMarital(true)
    if (!typeProfil) setErrorsTypeProfill(true)
    if (
      userData.data.role !== 'ROLE_COMMERCIAL' &&
      userData.data.role !== 'ROLE_RECRUTEUR' &&
      commercialSelect.length === 0
    )
      setErrorsCommercial(true)

    if (competences.length === 0) setErrorCompetences(true)
    if (competencesAnnexe.length === 0) setErrorCompetencesAnnexe(true)
    if (!file) setErrorFiles(true)
    if (
      !posteSelect ||
      !statusMarital ||
      !typeProfil ||
      !file ||
      errorsCommercial ||
      errorCompetences ||
      errorCompetencesAnnexe
    )
      return

    const formData = new FormData()
    formData.append('genre', statusMarital)
    formData.append(
      'entreprises',
      userData.data.role === 'ROLE_COMMERCIAL' ||
        userData.data.role === 'ROLE_RECRUTEUR'
        ? JSON.stringify([userData.data.id])
        : JSON.stringify(commercialSelect.map((obj) => obj.id))
    )
    formData.append('status', params.type === 'client' ? 'active' : 'classic')
    formData.append('prenom', data.prenom)
    formData.append('nom', data.nom)
    formData.append('password', data.email)
    formData.append('email', data.email)
    formData.append('telephone', data.telephone)
    formData.append('adresse', data.adresse)
    formData.append('ville', selectedCity)
    formData.append('codePostal', data.codePostal)
    formData.append('pays', selectedCountry.label)
    formData.append('nationalite', data.nationalite)
    formData.append('commentaire', data.commentaire)
    formData.append('dateNaissance', dateValue.format('DD/MM/YYYY'))
    /* formData.append('cv', file ? file : null) */
    Array.from(file).map((doc) => {
      formData.append('cv[]', doc)
    })
    formData.append('profile.mobilite', 50)
    formData.append('profile.ville', selectedCity)
    formData.append('profile.poste', posteSelect.id)
    formData.append(
      'profile.competences',
      competences.map((item) => item.technology)
    )
    formData.append(
      'profile.competencesAnnexe',
      competencesAnnexe.map((item) => item.technology)
    )
    formData.append('profile.softskils', JSON.stringify(softskils))
    /* formData.append(
      'profile.valeurs',
      JSON.stringify(valeurs.map((item) => item.technology))
    ) */
    formData.append('profile.typeDisponibilite', disponibilitesSelect.id)
    formData.append('profile.disponibleSous', disponibleSousSelect.id)
    formData.append('profile.disponible', dateDisponibleValue)
    formData.append('profile.salaire', data.salaire)
    formData.append('profile.typeProfil', typeProfil)
    if (typeProfil === 'Candidat Partenaire ESN') {
      formData.append('profile.genreCommercial', statusMaritalCommercial)
      formData.append('profile.nomCommercial', data.nomCommercial)
      formData.append('profile.prenomCommercial', data.prenomCommercial)
      formData.append('profile.emailCommercial', data.emailCommercial)
      formData.append('profile.telephoneCommercial', data.telephoneCommercial)
      formData.append('profile.societeCommercial', data.societeCommercial)
    }
    /*const payload = {
      ...data,
      roles: ["ROLE_CANDIDAT"],
      password: "password",
      status: "classic",
      cv: form,
      mobilite: 50,
    };*/
    addCandidate(formData)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading
          handleClickIcon={() =>
            navigate('/admin/gestion-prospect/liste-prospects-candidats')
          }
        >
          Ajouter un candidat
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '50px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          <form>
            <Grid container spacing={2} className={'gridContainer'}>
              {userData.data.role !== 'ROLE_COMMERCIAL' &&
                userData.data.role !== 'ROLE_RECRUTEUR' && (
                  <Grid item xs={12} sm={12} lg={12}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Commercial(s)
                        <span
                          style={{
                            color: lightTheme.palette.error.main,
                          }}
                        >
                          *
                        </span>
                      </FormLabel>

                      {loadingCommerc ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <Autocomplete
                            sx={{ width: '100%', backgroundColor: '#fff' }}
                            multiple
                            defaultValue={commercialSelect}
                            options={commercials}
                            getOptionLabel={(option) =>
                              option.nom +
                              ' ' +
                              option.prenom +
                              ' (' +
                              option.email +
                              ')'
                            }
                            onChange={(event, newValue) => {
                              setCommercialSelect(newValue)
                            }}
                            renderTags={(commercials, getTagProps) =>
                              commercials.map((commercial, index) => (
                                <Chip
                                  variant="outlined"
                                  label={
                                    commercial.nom + ' ' + commercial.prenom
                                  }
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField {...params} placeholder={' '} />
                            )}
                          />
                        </>
                      )}
                      {errorsCommercial && (
                        <Typography className={'error-message'}>
                          {
                            'Ce champ est obligatoire. Veuillez sélectionner un commercial.'
                          }
                        </Typography>
                      )}
                    </InputContainer>
                  </Grid>
                )}
              <Grid item xs={12} sm={3} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Type profil
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <Autocomplete
                    selectOnFocus
                    disableClearable
                    onChange={(event, newValue) => {
                      setTypeProfil(newValue.id)
                    }}
                    id="status-marital"
                    options={typeProfilList}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {errorsTypeProfil && (
                    <Typography className={'error-message'}>
                      {
                        'Ce champ est obligatoire. Veuillez sélectionner un status marital.'
                      }
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Genre
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <Autocomplete
                    selectOnFocus
                    disableClearable
                    onChange={(event, newValue) => {
                      setStatusMarital(newValue.id)
                    }}
                    id="status-marital"
                    options={statusMaritalList}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {errorsStatusMarital && (
                    <Typography className={'error-message'}>
                      {
                        'Ce champ est obligatoire. Veuillez sélectionner un status marital.'
                      }
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Prénom
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <TextInputClassic
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('prenom') }}
                  />
                  {errors.prenom && (
                    <Typography className={'error-message'}>
                      {errors.prenom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Nom
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <TextInputClassic
                    placeholder={'Nom'}
                    width={'100%'}
                    register={{ ...register('nom') }}
                  />
                  {errors.nom && (
                    <Typography className={'error-message'}>
                      {errors.nom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>

              <Grid item xs={12} sm={4} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Poste
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <Autocomplete
                    id="asynchronous-postes"
                    sx={{ width: '100%', minHeight: '45px' }}
                    open={open}
                    onChange={(event, newValue) => {
                      setPosteSelect(newValue)
                    }}
                    onKeyDown={(event, newInputValue) => {
                      clearTimeout(typingTimer)
                      if (event.target.value) {
                        typingTimer = setTimeout(function () {
                          getPostes(event.target.value)
                        }, doneTypingInterval)
                      }
                    }}
                    onOpen={() => {
                      setOpen(true)
                    }}
                    onClose={() => {
                      setOpen(false)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.titre === value.titre
                    }
                    getOptionLabel={(option) => option.titre}
                    options={postes}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        id="text-asynchronous-postes"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errorsJob && (
                    <Typography className={'error-message'}>
                      {
                        'Ce champ est obligatoire. Veuillez sélectionner un poste.'
                      }
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Mail
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <TextInputClassic
                    placeholder={'Mail'}
                    width={'100%'}
                    type={'email'}
                    register={{ ...register('email') }}
                  />
                  {errors.email && (
                    <Typography className={'error-message'}>
                      {errors.email.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Numéro de téléphone
                  </FormLabel>
                  {/*<TextInputClassic
                    placeholder={"Numéro de téléphone"}
                    width={"100%"}
                    register={{...register("telephone")}}
                  />*/}

                  <Controller
                    name="phone-input"
                    control={control}
                    rules={{
                      validate: (value) => isValidPhoneNumber(value),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        international
                        value={phoneValue}
                        onChange={setPhoneValue}
                        defaultCountry="FR"
                        id="phone-input"
                        {...register('telephone')}
                        containerStyle={{
                          border: '10px solid black',
                          background: 'pink',
                        }}
                        inputStyle={{
                          background: 'lightblue',
                        }}
                      />
                    )}
                  />

                  {errors.telephone && (
                    <Typography className={'error-message'}>
                      {errors.telephone.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Adresse</FormLabel>
                  <TextInputClassic
                    placeholder={'Adresse'}
                    width={'100%'}
                    register={{ ...register('adresse') }}
                  />
                  {errors.adresse && (
                    <Typography className={'error-message'}>
                      {errors.adresse.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Pays
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <Autocomplete
                    disableClearable
                    sx={{
                      width: '100%',
                      backgroundColor: '#fff',
                    }}
                    value={selectedCountry ? selectedCountry : {}}
                    onChange={(event, newValue) => {
                      handleCountryChange(newValue)
                    }}
                    id="country-select"
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Ville
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <Autocomplete
                    disableClearable
                    sx={{
                      width: '100%',
                      backgroundColor: '#fff',
                    }}
                    value={selectedCity ? selectedCity : {}}
                    onChange={(event, newValue) => {
                      setSelectedCity(newValue)
                    }}
                    id="country-select"
                    options={cities}
                    autoHighlight
                    //getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Code postal</FormLabel>
                  <TextInputClassic
                    placeholder={'Code postal'}
                    width={'100%'}
                    register={{ ...register('codePostal') }}
                  />
                  {errors.codePostal && (
                    <Typography className={'error-message'}>
                      {errors.codePostal.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Nationalité</FormLabel>
                  <TextInputClassic
                    placeholder={'Nationalité'}
                    width={'100%'}
                    register={{ ...register('nationalite') }}
                  />
                  {errors.nationalite && (
                    <Typography className={'error-message'}>
                      {errors.nationalite.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Date de naissance
                  </FormLabel>
                  <Box class="fc-right-custom">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={'fr'}
                    >
                      <DatePicker
                        onChange={setDateValue}
                        value={dateValue}
                        inputFormat={'DD/MM/YYYY'}
                        register={{ ...register('dateNaissance') }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              height: 45,
                              backgroundColor: lightTheme.palette.primary.b2,
                              borderRadius: '5px',

                              '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root': {
                                height: '100%',
                                width: '50px',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                                '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root':
                                  {
                                    margin: 0,
                                    color: '#FFFFFF',
                                  },
                              },
                            }}
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '5px 0 0 5px',
                                border: '1px solid #9E9E9E',
                                padding: '10px 15px',
                                fontFamily: 'Century Gothic',
                                fontSize: 14,
                                lineHeight: '140%',
                                letterSpacing: '0.2px',
                                color: lightTheme.palette.text.primary,
                                backgroundColor: '#FFFFFF',
                              }}
                              register={{ ...register('dateNaissance') }}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                    {errors.dateNaissance && (
                      <Typography className={'error-message'}>
                        {errors.dateNaissance.message}
                      </Typography>
                    )}
                  </Box>
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    {typeProfil === 'Candidat à embaucher' ||
                    typeProfil === 'Consultant Hcube'
                      ? 'Salaire annuel'
                      : 'TJM'}
                  </FormLabel>
                  <TextInputClassic
                    placeholder={
                      typeProfil === 'Candidat à embaucher' ||
                      typeProfil === 'Consultant Hcube'
                        ? '10000'
                        : '200'
                    }
                    type={'text'}
                    register={{ ...register('salaire') }}
                  />
                  {/* {errors.salaire && (
                    <Typography className={'error-message'}>
                      {errors.salaire.message}
                    </Typography>
                  )} */}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    {t('box.available')}
                  </FormLabel>
                  <Autocomplete
                    disableClearable
                    value={disponibilitesSelect ? disponibilitesSelect : {}}
                    id="disponibilitesSelect"
                    sx={{ width: '100%', minHeight: '45px' }}
                    onChange={(event, newValue) => {
                      setDisponibilitesSelect(newValue)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.titre === value.titre
                    }
                    getOptionLabel={(option) => option.titre}
                    options={disponibilitesList}
                    renderInput={(params) => (
                      <TextField
                        id="text-disponibilitesSelect"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              {disponibilitesSelect.id === 2 && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {t('box.availableWhen')}
                  </FormLabel>
                  <Autocomplete
                    disableClearable
                    value={disponibleSousSelect ? disponibleSousSelect : {}}
                    id="disponibleSousSelect"
                    sx={{ width: '100%', minHeight: '45px' }}
                    onChange={(event, newValue) => {
                      setDisponibleSousSelect(newValue)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.titre === value.titre
                    }
                    getOptionLabel={(option) => option.titre}
                    options={disponibleSousList}
                    renderInput={(params) => (
                      <TextField
                        id="text-disponibleSousSelect"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {disponibilitesSelect.id === 4 && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {t('box.availableWhen')}
                  </FormLabel>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={'fr'}
                  >
                    <DatePicker
                      /* openTo="year"
                            views={['year']} */
                      onChange={changeDateDisponible}
                      value={dateDisponibleValue}
                      inputFormat={'DD/MM/YYYY'}
                      register={{ ...register('disponible') }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            height: 45,
                            backgroundColor: lightTheme.palette.primary.b2,
                            borderRadius: '5px',

                            '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root': {
                              height: '100%',
                              width: '50px',
                              margin: 0,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',

                              '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root':
                                {
                                  margin: 0,
                                  color: '#FFFFFF',
                                },
                            },
                          }}
                        >
                          <input
                            ref={inputRef}
                            {...inputProps}
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '5px 0 0 5px',
                              border: '1px solid #9E9E9E',
                              padding: '10px 15px',
                              fontFamily: 'Century Gothic',
                              fontSize: 14,
                              lineHeight: '140%',
                              letterSpacing: '0.2px',
                              color: lightTheme.palette.text.primary,
                              backgroundColor: '#FFFFFF',
                            }}
                            register={{ ...register('disponible') }}
                          />
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
              {typeProfil === 'Candidat Partenaire ESN' && (
                <>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Divider>
                      <Chip label="Contact Commercial ESN" size="small" />
                    </Divider>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Genre
                        <span
                          style={{
                            color: lightTheme.palette.error.main,
                          }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <Autocomplete
                        selectOnFocus
                        disableClearable
                        onChange={(event, newValue) => {
                          setStatusMaritalCommercial(newValue.id)
                        }}
                        id="status-marital"
                        options={statusMaritalList}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </InputContainer>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Prénom
                        <span
                          style={{
                            color: lightTheme.palette.error.main,
                          }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <TextInputClassic
                        placeholder={'Prénom du commercial'}
                        width={'100%'}
                        register={{ ...register('prenomCommercial') }}
                      />
                      {errors.prenomCommercial && (
                        <Typography className={'error-message'}>
                          {errors.prenomCommercial.message}
                        </Typography>
                      )}
                    </InputContainer>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Nom
                        <span
                          style={{
                            color: lightTheme.palette.error.main,
                          }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <TextInputClassic
                        placeholder={'Nom du commercial'}
                        width={'100%'}
                        register={{ ...register('nomCommercial') }}
                      />
                      {errors.nomCommercial && (
                        <Typography className={'error-message'}>
                          {errors.nomCommercial.message}
                        </Typography>
                      )}
                    </InputContainer>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Mail
                        <span
                          style={{
                            color: lightTheme.palette.error.main,
                          }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <TextInputClassic
                        placeholder={'Mail du commercial'}
                        width={'100%'}
                        type={'email'}
                        register={{ ...register('emailCommercial') }}
                      />
                      {errors.emailCommercial && (
                        <Typography className={'error-message'}>
                          {errors.emailCommercial.message}
                        </Typography>
                      )}
                    </InputContainer>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Numéro de téléphone
                      </FormLabel>
                      {/*<TextInputClassic
                    placeholder={"Numéro de téléphone"}
                    width={"100%"}
                    register={{...register("telephone")}}
                  />*/}

                      <Controller
                        name="phone-input"
                        control={control}
                        rules={{
                          validate: (value) => isValidPhoneNumber(value),
                        }}
                        render={({ field: { onChange, value } }) => (
                          <PhoneInput
                            international
                            value={phoneValue}
                            onChange={setPhoneValue}
                            defaultCountry="FR"
                            id="phone-input"
                            {...register('telephoneCommercial')}
                            containerStyle={{
                              border: '10px solid black',
                              background: 'pink',
                            }}
                            inputStyle={{
                              background: 'lightblue',
                            }}
                          />
                        )}
                      />

                      {errors.telephoneCommercial && (
                        <Typography className={'error-message'}>
                          {errors.telephoneCommercial.message}
                        </Typography>
                      )}
                    </InputContainer>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Société
                        <span
                          style={{
                            color: lightTheme.palette.error.main,
                          }}
                        >
                          *
                        </span>
                      </FormLabel>
                      <TextInputClassic
                        placeholder={'Société du commercial'}
                        width={'100%'}
                        register={{ ...register('societeCommercial') }}
                      />
                      {errors.societeCommercial && (
                        <Typography className={'error-message'}>
                          {errors.societeCommercial.message}
                        </Typography>
                      )}
                    </InputContainer>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Compétences Techniques Principales (5 maximum)
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>

                  <Autocomplete
                    disableClearable
                    multiple
                    value={competences ? competences : {}}
                    id="asynchronous-environnements"
                    sx={{ width: '100%', minHeight: '45px' }}
                    open={competenceOpen}
                    onChange={(event, newValue) => {
                      setCompetences(newValue)
                    }}
                    onKeyDown={(event, newInputValue) => {
                      clearTimeout(typingTimer)
                      if (event.target.value) {
                        typingTimer = setTimeout(function () {
                          getEnvironnements(event.target.value)
                        }, doneTypingInterval)
                      }
                    }}
                    onOpen={() => {
                      setCompetenceOpen(true)
                    }}
                    onClose={() => {
                      setCompetenceOpen(false)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.technology === value.technology
                    }
                    groupBy={(option) => option.title} // Grouper par "titre"
                    getOptionLabel={(option) => option.technology}
                    options={environnements}
                    loading={loadingCompetence}
                    renderTags={(environnements, getTagProps) =>
                      environnements.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.technology}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        id="text-asynchronous-environnements"
                        {...params}
                        placeholder="Ajouter les compétences techniques principales"
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingCompetence ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />

                  {/* <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    options={competences.map((option) => option)}
                    onChange={(event, newValue) => {
                      setCompetences(newValue)
                    }}
                    freeSolo
                    renderTags={(competences, getTagProps) =>
                      competences.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ajouter les compétences techniques principales"
                      />
                    )}
                  /> */}

                  {errorCompetences && (
                    <Typography className={'error-message'}>
                      {
                        "Merci d'ajouter les compétences techniques principales."
                      }
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Compétences Techniques Secondaires
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>

                  <Autocomplete
                    disableClearable
                    multiple
                    value={competencesAnnexe ? competencesAnnexe : {}}
                    id="asynchronous-environnements"
                    sx={{ width: '100%', minHeight: '45px' }}
                    open={competenceAnnexeOpen}
                    onChange={(event, newValue) => {
                      setCompetencesAnnexe(newValue)
                    }}
                    onKeyDown={(event, newInputValue) => {
                      clearTimeout(typingTimer)
                      if (event.target.value) {
                        typingTimer = setTimeout(function () {
                          getEnvironnements(event.target.value)
                        }, doneTypingInterval)
                      }
                    }}
                    onOpen={() => {
                      setCompetenceAnnexeOpen(true)
                    }}
                    onClose={() => {
                      setCompetenceAnnexeOpen(false)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.technology === value.technology
                    }
                    groupBy={(option) => option.title} // Grouper par "titre"
                    getOptionLabel={(option) => option.technology}
                    options={environnements}
                    loading={loadingCompetence}
                    renderTags={(environnements, getTagProps) =>
                      environnements.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.technology}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        id="text-asynchronous-environnements"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        placeholder="Ajouter les compétences techniques secondaires"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingCompetence ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />

                  {/* <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    options={competencesAnnexe.map((option) => option)}
                    onChange={(event, newValue) => {
                      setCompetencesAnnexe(newValue)
                    }}
                    freeSolo
                    renderTags={(competencesAnnexe, getTagProps) =>
                      competencesAnnexe.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ajouter les compétences techniques secondaires"
                      />
                    )}
                  /> */}

                  {errorCompetencesAnnexe && (
                    <Typography className={'error-message'}>
                      {
                        "Merci d'ajouter les compétences techniques secondaires."
                      }
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Softskils</FormLabel>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    options={[...SOFTSKILLS['fr']].sort()}
                    onChange={(event, newValue) => {
                      setSoftskils(newValue)
                    }}
                    renderTags={(softskils, getTagProps) =>
                      softskils.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ajouter les softskils"
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              {/* <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Environnements Techniques
                  </FormLabel>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    options={environnementsTechniques}
                    groupBy={(option) => option.title} // Grouper par "titre"
                    getOptionLabel={(option) => option.technology}
                    onChange={(event, newValue) => {
                      setValeurs(newValue)
                    }}
                    renderTags={(valeurs, getTagProps) =>
                      valeurs.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.technology}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ajouter les environnements techniques"
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                  />
                </InputContainer>
              </Grid> */}
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Commentaires
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <TextareaInputClassic
                    placeholder={'Commentaires'}
                    register={{ ...register('commentaire') }}
                    type={'border'}
                  />
                  {errors.commentaire && (
                    <Typography className={'error-message'}>
                      {errors.commentaire.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <ButtonInputUpload onChange={handleFileChange} />
                  <p>
                    {file.length > 0 &&
                      Array.from(file)
                        .map((item) => item.name)
                        .join(', ')}
                  </p>
                </InputContainer>

                {errorFiles && (
                  <Typography className={'error-message'}>
                    {'Merci de télécharger au moins un CV.'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>

          <Box
            className={'wrapperButtons'}
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Box className={'blockButtons'}>
              {loadingAddAdmin ? (
                <CircularProgress />
              ) : (
                <>
                  <BorderButtonPurple
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={() =>
                      navigate(
                        '/admin/gestion-prospect/liste-prospects-candidats'
                      )
                    }
                  >
                    Annuler
                  </BorderButtonPurple>
                  <CustomButton
                    displayIcon={'none'}
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={handleSubmit(onSubmit)}
                  >
                    Ajouter
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default AddCandidate
