import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AdminLayout } from '../components/layouts'
import { Candidate, Company } from '../pages/Admin/User'
import { Dashboard, MenuDashboard } from '../pages/Admin/Dashboard'
import {
  ProfilAdmin,
  GestionAdmins,
  AddAdmin,
  GestionDatas,
} from '../pages/Admin/Admin'
import CandidateProfil from '../pages/Admin/User/Candidate/CandidateProfil'
import { CompanyProfil } from '../pages/Admin/User/Company'
import { ListProspectCompany } from '../pages/Admin/User/Prospect/CompanyProspect'
import {
  ListProspectCandidate,
  ProspectCandidateProfil,
} from '../pages/Admin/User/Prospect/CandidateProspect'
import Calendar from '../pages/Admin/Calendar/Calendar'
import Disponibilities from '../pages/Admin/Calendar/Disponibilities'
import DemandeRdv from '../pages/Admin/User/Prospect/DemandeRdv'
import DemandeRendezVous from '../pages/Admin/Calendar/DemandeRendezVous'
import AddProspectCandidate from '../pages/Admin/User/Prospect/CandidateProspect/AddProspectCandidate'
import AddProspectCompany from '../pages/Admin/User/Prospect/CompanyProspect/AddProspectCompany'
import AddCandidate from '../pages/Admin/User/Candidate/AddCandidate'
import AddCompany from '../pages/Admin/User/Company/AddCompany'
import MatchingCandidate from '../pages/Admin/User/Candidate/MatchingCandidate'
import GestionCommercials from '../pages/Admin/Commercial/GestionCommercials'
import AddCommercial from '../pages/Admin/Commercial/AddCommercial'
import ProfilCommercial from '../pages/Admin/Commercial/ProfilCommercial'
import ContactCompanyProfil from '../pages/Admin/User/Company/ContactCompanyProfil'
import BesoinsEntreprise from '../pages/Admin/User/Company/Besoins'
import GestionEnvironnements from '../pages/Admin/Admin/GestionEnvironnements'
import ProfilDashboard from '../pages/Admin/Dashboard/ProfilDashboard'

const AdminRouter = () => {
  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route index element={<MenuDashboard />} />
        <Route path={'dashboard'} element={<ProfilDashboard />} />

        <Route path={'mon-profil'} element={<Dashboard />} />
        <Route path={'gestion-admins'} element={<GestionAdmins />} />
        <Route path={'gestion-donnees/postes'} element={<GestionDatas />} />
        <Route path={'gestion-donnees/environnements'} element={<GestionEnvironnements />} />

        <Route path={'gestion-admins'}>
          <Route path={'ajouter-admin'} element={<AddAdmin />} />
          <Route path={'profil-admin/:adminId'} element={<ProfilAdmin />} />
        </Route>

        <Route path={'gestion-commerciaux'} element={<GestionCommercials />} />
        <Route path={'gestion-commerciaux'}>
          <Route path={'ajouter-commercial'} element={<AddCommercial />} />
          <Route
            path={'profil-commercial/:adminId'}
            element={<ProfilCommercial />}
          />
        </Route>

        <Route path={'gestion-entreprise'}>
          <Route path={'liste/:type'} element={<Company />} />
          <Route
            path={'profil-entreprise/:userId'}
            element={<CompanyProfil />}
          />
          <Route path={'ajouter-entreprise/:type'} element={<AddCompany />} />
          <Route
            path={'liste-besoins-entreprises'}
            element={<BesoinsEntreprise />}
          />
          <Route
            path={'contact-entreprise/:userId'}
            element={<ContactCompanyProfil />}
          />

          <Route path={'liste-clients-candidats'} element={<Candidate />} />
          <Route
            path={'profil-client-candidat/:userId'}
            element={<CandidateProfil />}
          />
          <Route path={'ajouter-candidat/:type'} element={<AddCandidate />} />
        </Route>

        <Route path={'besoin-entreprise'}>
          <Route path={':type'} element={<BesoinsEntreprise />} />
          <Route path={'detail/:besoinId'} element={<MatchingCandidate />} />
        </Route>

        <Route path={'gestion-client'}>
          <Route path={'liste-clients-candidats'} element={<Candidate />} />
          <Route
            path={'profil-client-candidat/:userId'}
            element={<CandidateProfil />}
          />
          <Route path={'ajouter-candidat/:type'} element={<AddCandidate />} />
        </Route>

        <Route path={'gestion-prospect'}>
          <Route
            path={'liste-prospects-entreprises'}
            element={<ListProspectCompany />}
          />
          <Route
            path={'profil-entreprise/:userId'}
            element={<CompanyProfil />}
          />
          <Route path={'ajouter-entreprise'} element={<AddCompany />} />

          <Route
            path={'liste-prospects-candidats'}
            element={<ListProspectCandidate />}
          />
          <Route
            path={'ajouter-prospect-candidats'}
            element={<AddProspectCandidate />}
          />
          <Route
            path={'profil-prospect-candidat/:userId'}
            element={<ProspectCandidateProfil />}
          />

          <Route path={'demandes-de-rendez-vous'} element={<DemandeRdv />} />
        </Route>

        <Route path={'gestion-partenaire'}>
          <Route
            path={'liste-partenaires-entreprises'}
            element={<ListProspectCompany />}
          />
          <Route
            path={'profil-entreprise/:userId'}
            element={<CompanyProfil />}
          />
          <Route path={'ajouter-entreprise'} element={<AddCompany />} />
        </Route>

        <Route path={'gestion-esn'}>
          <Route
            path={'liste-esn-entreprises'}
            element={<ListProspectCompany />}
          />
          <Route
            path={'profil-entreprise/:userId'}
            element={<CompanyProfil />}
          />
          <Route path={'ajouter-entreprise'} element={<AddCompany />} />
        </Route>

        <Route path={'calendrier'}>
          <Route index element={<Calendar />} />
          <Route
            path={'definir-les-disponibilites'}
            element={<Disponibilities />}
          />
          <Route
            path={'demande-de-rendez-vous'}
            element={<DemandeRendezVous />}
          />
        </Route>
      </Route>
    </Routes>
  )
}

export default AdminRouter
