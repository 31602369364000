import React, { useCallback, useEffect, useState } from 'react'
import { MasonryLayout } from '../../../../components/layouts'
import { Box, CircularProgress, styled, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faHome,
  faPhone,
  faPen,
  faCircleExclamation,
  faArrowLeft,
  faCircleCheck,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../styles/theme/lightTheme'
import {
  EditInfos,
  EditDocuments,
  ChoiceOfferModal,
  EditEvent,
} from '../../../../components/modals'
import { useDispatch } from 'react-redux'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import dayjs from 'dayjs'
import BiBlockTable from '../../../../components/tables/BiBlockTable'
import moment from 'moment/moment'
import { OffreTable } from '../../../../components/tables'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import EditBesoin from '../../../../components/modals/EditBesoin'
import EditList from '../../../../components/modals/EditList'
import DeleteGeneralModal from '../../../../components/modals/DeleteGeneralModal'
import {
  SOFTSKILLS,
  disponibilitesList2,
  disponibleSousList2,
  environnementsTechniques,
} from '../../../../data'
import EditResume from '../../../../components/modals/EditResume'
import EditCommentaire from '../../../../components/modals/EditCommentaire'
import GedTabs from '../../../../components/tables/GedTabs'
import { faPenCircle } from '@fortawesome/pro-solid-svg-icons'
import DetailOfferModal from '../../../../components/modals/DetailOfferModal'

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 80,
  height: 30,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const ProfilContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '45px',
  justifyContent: 'space-between',
}))

const WrapperProfilText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '5px',
}))

const BannerProfil = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 40,
  backgroundColor: lightTheme.palette.primary.b6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
}))

const WrapperBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const BannerProfilContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const OffreContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '20px',
  height: '100%',
}))

const BesoinContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '20px',
  height: '100%',
}))

const KeywordList = styled('ul')(({ theme }) => ({
  display: 'flex',
  paddingLeft: '15px',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  rowGap: '5px',
}))

const CandidateProfil = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openResume, setOpenResume] = useState(false)
  const [openCommentaire, setOpenCommentaire] = useState(false)
  const [openValeurs, setOpenValeurs] = useState(false)
  const [openCompetences, setOpenCompetences] = useState(false)
  const [openCompetencesAnnexe, setOpenCompetencesAnnexe] = useState(false)
  const [openSoftskils, setOpenSoftskils] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingTest, setLoadingTest] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const dispatch = useDispatch()
  const [content, setContent] = useState([])
  const [besoin, setBesoin] = useState([])
  const [resume, setResume] = useState([])
  const [commentaire, setCommentaire] = useState([])
  const [dateValue, setDateValue] = useState(dayjs(new Date()))
  const [dateRdv, setDateRdv] = useState([])
  const [isFile, setIsFile] = useState([])
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    fetchData()
  }
  const handleOpenDoc = useCallback(() => setOpenDoc(true), [])
  const handleCloseDoc = useCallback(() => {
    setOpenDoc(false)
    //fetchData()
  }, [])
  const handleOpenBesoin = useCallback(() => setOpenBesoin(true), [])
  const handleCloseBesoin = useCallback(() => {
    setOpenBesoin(false)
    //fetchData()
  }, [])
  const handleOpenResume = useCallback(() => setOpenResume(true), [])
  const handleCloseResume = useCallback(() => {
    setOpenResume(false)
    //fetchData()
  }, [])
  const handleOpenCompetences = useCallback(() => setOpenCompetences(true), [])
  const handleCloseCompetences = useCallback(() => {
    setOpenCompetences(false)
    //fetchData()
  }, [])
  const handleOpenCompetencesAnnexe = () => setOpenCompetencesAnnexe(true)
  const handleCloseCompetencesAnnexe = () => {
    setOpenCompetencesAnnexe(false)
    fetchData()
  }
  const handleOpenCommentaire = () => setOpenCommentaire(true)
  const handleCloseCommentaire = () => {
    setOpenCommentaire(false)
    fetchData()
  }
  const handleOpenValeurs = useCallback(() => setOpenValeurs(true), [])
  const handleCloseValeurs = useCallback(() => {
    setOpenValeurs(false)
    //fetchData()
  }, [])
  const handleOpenSoftskils = useCallback(() => setOpenSoftskils(true), [])
  const handleCloseSoftskils = useCallback(() => {
    setOpenSoftskils(false)
    //fetchData()
  }, [])
  const handleOpenOffer = useCallback(() => setOpenOffre(true), [])
  const handleCloseOffer = useCallback(() => {
    setOpenOffre(false)
    //fetchData()
  }, [])

  const handleSave = () => {
    fetchData()
    setOpen(false)
    setOpenDoc(false)
    setOpenBesoin(false)
    setOpenResume(false)
    setOpenCommentaire(false)
    setOpenCompetences(false)
    setOpenCompetencesAnnexe(false)
    setOpenSoftskils(false)
    setOpenValeurs(false)
    setOpenOffre(false)
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => removeFile()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [fileDelete, setFileDelete] = useState()

  const [openOffre, setOpenOffre] = useState(false)
  const [offres, setOffres] = useState([])
  const [offreSelect, setOffreSelect] = useState('')
  const [offresSelect, setOffresSelect] = useState([])
  const [loadingOffer, setLoadingOffer] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)

  const [dateRelation, setDateRelation] = useState([])
  const [openEvent, setOpenEvent] = useState(false)
  const [event, setEvent] = useState({})

  const handleOpenEvent = (event) => {
    setEvent(event)
    setOpenEvent(true)
  }
  const handleCloseEvent = () => {
    setOpenEvent(false)
    fetchData()
  }

  const params = useParams()

  useEffect(() => {
    getOffers()
  }, [])
  const getOffers = async () => {
    try {
      let result = await Api.offer.listWithFilter({
        categorie: 'candidat',
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingOffer(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoadingOffer(false)
        setOffres(res)
      } else {
        setLoadingOffer(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingOffer(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const handleChangeSelect = (evt) => {
    const inputValue = evt.target.value
    setOffreSelect(inputValue)
  }

  const sendEvaluation = async () => {
    setLoadingTest(true)

    try {
      const result = await Api.candidate.sendEval(params.userId, {
        url: 'https://www.youtube.com/channel/UC8YZ2pn8zIzx_GnJw_F6fdQ',
      })
      const handling = handlingErrors(result)

      if (handling.ok) {
        toast.success('Le test de personnalité a été envoyé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
      setLoadingTest(false)
    } catch (error) {
      setLoadingTest(false)
    }
  }

  const handleRemove = (idFile) => {
    setOpenDeleteModal(true)
    setFileDelete(idFile)
  }

  const removeFile = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.document.delete(fileDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexFile = isFile.findIndex((file) => file.id === fileDelete)

        if (indexFile > -1) {
          isFile.splice(indexFile, 1)
        }
        setIsFile(isFile)
        setOpenDeleteModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }

  const fetchData = async () => {
    try {
      let result = await Api.user.detail(params.userId)

      setContent(result.data)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setDateRdv(res.candidat?.calendriers.sort((a, b) => a.id - b.id))
        let userOffres = []
        if (res.userOffres) {
          res.userOffres.map((userOffre, i) => {
            userOffres.push({
              id: userOffre.id,
              offre: `/api/offres/${userOffre.offre.id}`,
              prix: userOffre.prix,
            })
          })
        }
        setOffresSelect(userOffres)
        setBesoin(res.candidat?.besoin)
        setResume(res.candidat?.resume)
        setCommentaire(res.commentaire)
        setDateValue(res.candidat?.calendriers[0]?.dateDebut)
        let docs = []
        //if (res.candidat?.cv) docs.push({ type: 'cv', nom: res.candidat?.cv })
        if (res.documents) {
          res.documents.map((doc, i) => {
            docs.push(doc)
          })
        }

        setIsFile(docs)
        setLoading(false)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }
  useEffect(() => {
    if (params.userId) fetchData()
  }, [params.userId, dispatch])

  /*useEffect(() => {
    const DateFormat = format(new Date(dateValue), "dd/MM/yyyy");
    setDateRdv(DateFormat);
  })*/

  const [adresseId, setAdresseId] = useState()
  const [contactId, setContactId] = useState()
  const [openAction, setOpenAction] = useState(false)
  const [action, setAction] = useState()
  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false)
  const [openDetailAction, setOpenDetailAction] = useState(false)
  const handleCloseDeleteActionModal = () => setOpenDeleteActionModal(false)
  const handleConfirmDeleteActionModal = () => removeAction()
  const handleEditAction = (action) => {
    setAction(action)
    setOpenAction(true)
  }
  const handleDetailAction = (action) => {
    setAction(action)
    setOpenDetailAction(true)
  }
  const handleRemoveAction = (action) => {
    setAction(action)
    setOpenDeleteActionModal(true)
  }
  const handleCloseAction = () => {
    setOpenDetailAction(false)
    setAction()
  }
  const handleCloseEditAction = () => {
    setOpenAction(false)
    //setAction()
  }

  const removeAction = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.action.delete(action)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        fetchData()
        setOpenDeleteActionModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
    setAdresseId('')
  }

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <div style={{ width: '100%' }}>
      <Button
        sx={{
          margin: '10px',
        }}
        onClick={() =>
          navigate('/admin/gestion-client/liste-clients-candidats')
        }
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          fontSize={15}
          color={lightTheme.palette.primary.b2}
        />{' '}
        <Typography
          variant={'body2'}
          color={lightTheme.palette.primary.b2}
          textAlign={'right'}
          marginLeft={1}
        >
          {'Retour'}
        </Typography>
      </Button>
      <MasonryLayout
        type={'candidat'}
        handleOpenDoc={handleOpenDoc}
        handleOpenBesoin={handleOpenBesoin}
        handleOpenResume={handleOpenResume}
        handleOpenCommentaire={handleOpenCommentaire}
        handleOpenValeurs={handleOpenValeurs}
        handleOpenCompetences={handleOpenCompetences}
        handleOpenCompetencesAnnexe={handleOpenCompetencesAnnexe}
        handleOpenSoftskils={handleOpenSoftskils}
        handleOpenOffer={handleOpenOffer}
        profil={
          <ProfilContainer>
            <TitleContainer>
              <TitleSection></TitleSection>
              <IconContainer onClick={handleOpen}>
                <FontAwesomeIcon
                  icon={faPenCircle}
                  fontSize={40}
                  color={lightTheme.palette.primary.b2}
                />
              </IconContainer>
            </TitleContainer>
            <WrapperProfilText>
              <Typography
                variant={'subtitle2'}
                textAlign={'center'}
                textTransform={'capitalize'}
                sx={{ fontSize: '22px!important' }}
              >
                {snapShot.prenom} {snapShot.nom}
              </Typography>
              {snapShot.candidat?.poste && (
                <Typography
                  variant={'subtitle2'}
                  textAlign={'center'}
                  sx={{ fontSize: '20px!important' }}
                >
                  {snapShot.candidat.poste.titre}
                </Typography>
              )}
              {snapShot.candidat?.typeProfil && (
                <Typography variant={'subtitle2'} textAlign={'center'}>
                  {snapShot.candidat?.typeProfil}
                </Typography>
              )}
              {snapShot.candidat?.salaire && (
                <Typography variant={'body2'} textAlign={'center'}>
                  {snapShot?.candidat?.typeProfil ===
                    'Candidat Partenaire ESN' ||
                  snapShot?.candidat?.typeProfil === 'Consultant Hcube'
                    ? 'TJM'
                    : 'Salaire annuel'}{' '}
                  : {snapShot.candidat?.salaire} €
                </Typography>
              )}
              {snapShot.candidat?.experiences && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {snapShot.candidat.experiences}
                </Typography>
              )}
              {snapShot.candidat?.typeDisponibilite && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {
                    disponibilitesList2.find(
                      (dispo) =>
                        dispo.id == snapShot.candidat?.typeDisponibilite
                    ).titre['fr']
                  }

                  {snapShot.candidat?.typeDisponibilite == 2 &&
                    snapShot.candidat?.disponibleSous &&
                    disponibleSousList2.find(
                      (dispoS) => dispoS.id == snapShot.candidat?.disponibleSous
                    ).titre['fr']}

                  {snapShot.candidat?.typeDisponibilite == 4 &&
                    moment(snapShot.candidat?.disponible)
                      .tz('Europe/Paris')
                      .format('DD/MM/YYYY')}
                </Typography>
              )}
            </WrapperProfilText>
            <BannerProfilContainer>
              <WrapperBanner>
                {/* {snapShot.langues?.length > 0 && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faComment} />
                    <Typography>{snapShot.langues}</Typography>
                  </BannerProfil>
                )} */}

                <BannerProfil>
                  <FontAwesomeIcon icon={faHome} />
                  <Typography>
                    {snapShot.ville} / {snapShot.pays}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <Typography
                    textAlign={'right'}
                    title={snapShot.email}
                    style={{
                      width: '90%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {snapShot.email}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faPhone} />
                  <Typography>{snapShot.telephone}</Typography>
                </BannerProfil>
                {/* {loadingTest ? (
                  <CircularProgress />
                ) : (
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={sendEvaluation}
                  >
                    Test de personnalité
                  </CustomButton>
                )} */}
              </WrapperBanner>
              {/*<BorderButtonPurple>Voir le résumé</BorderButtonPurple>*/}
            </BannerProfilContainer>
          </ProfilContainer>
        }
        besoinClient={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            {besoin}
          </Typography>
        }
        resumeClient={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            {resume}
          </Typography>
        }
        commentaireAdmin={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            {commentaire}
          </Typography>
        }
        rendezVous={
          <BiBlockTable
            rdvAVenir={dateRdv}
            heureAVenir={''}
            handleOpenEvent={handleOpenEvent}
            type={'candidat'}
          />
        }
        relation={
          <BiBlockTable
            rdvAVenir={dateRelation}
            heureAVenir={''}
            type={'candidat'}
          />
        }
        feedback={dateRdv}
        offre={
          snapShot.actionsCandidat && (
            <OffreTable
              handleRemoveAction={handleRemoveAction}
              handleEditAction={handleEditAction}
              handleDetailAction={handleDetailAction}
              type={'candidat'}
              offres={snapShot.actionsCandidat.sort((a, b) => b.id - a.id)}
              dateOffer={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
            />
          )
        }
        ged={
          isFile ? (
            <GedTabs
              files={isFile}
              dateFile={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
              handleRemove={handleRemove}
            />
          ) : (
            <GedTabs />
          )
        }
        displayCompetencesSection={'display'}
        competences={snapShot.candidat.competences}
        competencesAnnexe={snapShot.candidat.competencesAnnexe}
        displaySoftskilSection={'display'}
        softskils={snapShot.candidat.softskils}
        displayValeurSection={'display'}
        environnementsTechniques={snapShot.candidat.environnementsTechniques}
      />

      {open && (
        <EditInfos
          open={open}
          onClose={handleClose}
          onSave={handleSave}
          id={params.userId}
          role={'ROLE_ADMIN'}
          type={'ROLE_CANDIDAT'}
        />
      )}

      {openCommentaire && (
        <EditCommentaire
          open={openCommentaire}
          onClose={handleCloseCommentaire}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openResume && (
        <EditResume
          open={openResume}
          onClose={handleCloseResume}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openBesoin && (
        <EditBesoin
          open={openBesoin}
          onClose={handleCloseBesoin}
          id={params.userId}
        />
      )}

      {openDoc && (
        <EditDocuments
          open={openDoc}
          onClose={handleCloseDoc}
          onSave={handleSave}
          id={params.userId}
          role={'ROLE_CANDIDAT'}
        />
      )}

      {openCompetences && (
        <EditList
          open={openCompetences}
          onClose={handleCloseCompetences}
          id={snapShot.candidat.id}
          type={'competences'}
          onSave={handleSave}
          values={snapShot.candidat.competences}
          datas={[]}
        />
      )}

      {openCompetencesAnnexe && (
        <EditList
          open={openCompetencesAnnexe}
          onClose={handleCloseCompetencesAnnexe}
          id={snapShot.candidat.id}
          type={'competencesAnnexe'}
          onSave={handleSave}
          values={snapShot.candidat.competencesAnnexe}
          datas={[]}
        />
      )}

      {openSoftskils && (
        <EditList
          open={openSoftskils}
          onClose={handleCloseSoftskils}
          id={snapShot.candidat.id}
          type={'softskils'}
          onSave={handleSave}
          values={snapShot.candidat.softskils}
          datas={[...SOFTSKILLS['fr']].sort()}
        />
      )}

      {openValeurs && (
        <EditList
          open={openValeurs}
          onClose={handleCloseValeurs}
          id={snapShot.candidat.id}
          type={'Environnements Techniques'}
          onSave={handleSave}
          values={snapShot.candidat.environnementsTechniques}
          datas={[environnementsTechniques]}
        />
      )}

      {openOffre && (
        <ChoiceOfferModal
          open={openOffre}
          onClose={handleCloseOffer}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          /* value={offreSelect} */
          candidat={snapShot}
          loading={loadingButton}
          type={'candidat'}
        />
      )}

      {openAction && (
        <ChoiceOfferModal
          open={openAction}
          onClose={handleCloseEditAction}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          actionValue={action}
          candidat={snapShot}
          loading={loadingButton}
          type={'entreprise'}
        />
      )}

      {openDetailAction && (
        <DetailOfferModal
          open={openDetailAction}
          onClose={handleCloseAction}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          action={action}
          candidat={snapShot}
          loading={loadingButton}
          type={'action'}
          handleEdit={handleEditAction}
        />
      )}

      {openDeleteModal && (
        <DeleteGeneralModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteModal}
          loading={loadingDelete}
        />
      )}

      {openEvent && (
        <EditEvent
          open={openEvent}
          onClose={handleCloseEvent}
          id={params.userId}
          //role={role}
          event={event}
          onSave={handleCloseEvent}
          showParams={true}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </div>
  )
}

export default CandidateProfil
