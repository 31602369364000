import React, { useState } from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import { unserialize } from '../../config'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import './tables.css'
import { prioriteEntrepriselList } from '../../data'

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleTab = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const BodyTab = styled(TableCell)(({ theme, type }) => ({
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const BlockTabCircle = styled(TableCell)(({ theme }) => ({
  width: 25,
  /*height: 61,*/
  height: '100%',
  backgroundColor: lightTheme.palette.secondary.main,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.secondary.main}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',
  position: 'absolute',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 5,
    height: 5,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const BlockTabSquare = styled(TableCell)(({ theme }) => ({
  width: 25,
  /*height: 61,*/
  height: '100%',
  backgroundColor: lightTheme.palette.warning.dark,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.warning.dark}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',
  position: 'absolute',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 5,
    height: 5,
    backgroundColor: '#FFFFFF',
  },
}))

const BlockTab = styled(TableCell)(({ theme, type }) => ({
  width: 15,
  /*height: 60,*/
  height: '100%',
  padding: 0,
  backgroundColor:
    type === 'entreprise'
      ? lightTheme.palette.secondary.main
      : type === 'candidat'
      ? lightTheme.palette.warning.dark
      : lightTheme.palette.secondary.main,
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const EmptyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.grey.main,
  textAlign: 'center',
  fontStyle: 'italic',
  padding: '15px 0',
  background: 'rgba(0, 0, 0, 0.04)',
}))

const getTodayAtMidnight = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

const isDateInCurrentYear = (date) => {
  const currentYear = new Date().getFullYear() // Get the current year

  // First day of the current year (January 1st)
  const firstDayOfYear = new Date(currentYear, 0, 1) // Month is 0-indexed, so 0 = January

  // Last day of the current year (December 31st)
  const lastDayOfYear = new Date(currentYear, 11, 31) // Month is 0-indexed, so 11 = December

  // Convert besoin.date to a Date object
  const besoinDate = new Date(date)

  // Check if besoinDate is between firstDayOfYear and lastDayOfYear
  return besoinDate >= firstDayOfYear && besoinDate <= lastDayOfYear
}

const ListTable = ({
  type,
  items,
  setId,
  loading,
  handleClick,
  handleRemove,
  listeMatching = false,
  matchingPost = '',
  isAdmin = false,
}) => {
  const { t } = useTranslation()
  const userData = useSelector((state) => state.userAuth.user)
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Table>
          <HeaderTab>
            <TableRow>
              <TableCell width={25} sx={{ padding: 0 }} />
              {type === 'entreprise' ? (
                <>
                  <TitleTab>Nom entreprise</TitleTab>
                  {/* <TitleTab>{t('contact.lastName')}</TitleTab>
                  <TitleTab>{t('contact.firstName')}</TitleTab> */}
                  {/* <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                   Type
                  </TitleTab> */}
                  <TitleTab>Domaine d’activité</TitleTab>
                  <TitleTab width={300}>Responsable</TitleTab>
                  <TitleTab sx={{ textAlign: 'center' }}>
                    Besoins en cours
                  </TitleTab>
                  <TitleTab sx={{ textAlign: 'center' }}>
                    Besoins de l’année
                  </TitleTab>
                  <TitleTab sx={{ textAlign: 'center' }}>Priorité</TitleTab>
                </>
              ) : (
                <>
                  {listeMatching && (
                    <TitleTab>{t('header.candidate')}</TitleTab>
                  )}
                  {(!listeMatching || isAdmin) && (
                    <TitleTab>{t('contact.lastName')}</TitleTab>
                  )}
                  {(!listeMatching || isAdmin) && (
                    <TitleTab>{t('contact.firstName')}</TitleTab>
                  )}
                  <TitleTab>{t('filter.poste')}</TitleTab>
                  {!listeMatching && (
                    <TitleTab
                      sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                    >
                      {t('contact.email')}
                    </TitleTab>
                  )}
                  {!listeMatching && (
                    <>
                      {/*  <TitleTab
                        sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                      >
                        {t('header.offer')}
                      </TitleTab> */}
                      <TitleTab
                        sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                      >
                        {t('profile.date')}
                      </TitleTab>
                    </>
                  )}
                  {listeMatching && (
                    <>
                      <TitleTab>{t('filter.competences')}</TitleTab>
                      <TitleTab>{t('filter.languages')}</TitleTab>
                      <TitleTab>{t('filter.matching')}</TitleTab>
                    </>
                  )}
                </>
              )}

              <TableCell />
              <TableCell width={15} sx={{ padding: 0 }} />
            </TableRow>
          </HeaderTab>
          <TableBody>
            {items?.length > 0 && (
              <>
                {items.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ position: 'relative', cursor: 'pointer' }}
                    className="table-row"
                  >
                    {type === 'entreprise' ? (
                      <>
                        <BlockTabCircle
                          onClick={() => {
                            setId(item.id)
                            /*handleClick(`${item.nom} ${item.prenom}`);*/
                            handleClick(`${item.id}`, item)
                          }}
                        >
                          <div className={'firstCircle'} />
                          <div className={'secondCircle'} />
                          <div className={'thirdCircle'} />
                          <div className={'fourthCircle'} />
                          <div className={'fiveCircle'} />
                        </BlockTabCircle>
                        <BodyTab
                          type={type}
                          onClick={() => {
                            setId(item.id)
                            /*handleClick(`${item.nom} ${item.prenom}`);*/
                            handleClick(`${item.id}`, item)
                          }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                              textTransform: 'capitalize',
                            }}
                          >
                            {item.entreprise.raisonSocial}
                          </Typography>
                        </BodyTab>
                        {/* <BodyTab
                          type={type}
                          sx={{ textTransform: 'Capitalize' }}
                        >
                          {item.nom}
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ textTransform: 'Capitalize' }}
                        >
                          {item.prenom}
                        </BodyTab> */}
                        <BodyTab
                          type={type}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                          onClick={() => {
                            setId(item.id)
                            /*handleClick(`${item.nom} ${item.prenom}`);*/
                            handleClick(`${item.id}`, item)
                          }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.domainesActivites?.join(', ')}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }}
                            title={item.entreprises
                              ?.map((item) => item.nom + ' ' + item.prenom)
                              .join(' - ')}
                          >
                            {item.entreprises
                              ?.map((item) => item.nom + ' ' + item.prenom)
                              .join(' - ')}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          <Typography
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            {item.entreprise?.besoinEntreprise
                              ? item.entreprise?.besoinEntreprise.filter(
                                  (besoin) =>
                                    ['DC', 'PP', 'PC', 'NT', 'CL'].includes(
                                      besoin.typeContrat
                                    ) &&
                                    new Date(besoin.dateDebut) >
                                      getTodayAtMidnight()
                                ).length
                              : 0}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          <Typography
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            {item.entreprise?.besoinEntreprise
                              ? item.entreprise?.besoinEntreprise.filter(
                                  (besoin) =>
                                    [
                                      'DC',
                                      'PP',
                                      'PC',
                                      'NT',
                                      'CL',
                                      'CS',
                                      'MD',
                                    ].includes(besoin.typeContrat) &&
                                    isDateInCurrentYear(besoin.date)
                                ).length
                              : 0}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          onClick={() => {
                            setId(item.id)
                            handleClick(`${item.id}`, item)
                          }}
                        >
                          <Typography
                            variant={'body2'}
                            color={
                              item.priorite === '3'
                                ? lightTheme.palette.error.dark
                                : item.priorite === '2'
                                ? lightTheme.palette.warning.dark
                                : lightTheme.palette.grey.grey
                            }
                            textAlign={'center'}
                          >
                            {item.priorite ? 
                              prioriteEntrepriselList.find(
                                (prio) =>
                                  parseInt(prio.id) === parseInt(item.priorite)
                              ).titre : prioriteEntrepriselList.find(
                                (prio) =>
                                  parseInt(prio.id) === 0
                              ).titre}
                          </Typography>
                        </BodyTab>
                      </>
                    ) : (
                      <>
                        <BlockTabSquare
                          onClick={() => {
                            setId(item.id)
                            /*handleClick(`${item.nom} ${item.prenom}`);*/
                            handleClick(`${item.id}`, item)
                          }}
                        >
                          <div className={'firstSquare'} />
                          <div className={'secondSquare'} />
                          <div className={'thirdSquare'} />
                          <div className={'fourthSquare'} />
                          <div className={'fiveSquare'} />
                        </BlockTabSquare>
                        {listeMatching && (
                          <BodyTab
                            onClick={() => {
                              setId(item.id)
                              /*handleClick(`${item.nom} ${item.prenom}`);*/
                              handleClick(`${item.id}`, item)
                            }}
                            type={type}
                          >
                            #Jober000{item.id}
                          </BodyTab>
                        )}
                        {(!listeMatching || isAdmin) && (
                          <BodyTab
                            type={type}
                            onClick={() => {
                              setId(item.id)
                              /*handleClick(`${item.nom} ${item.prenom}`);*/
                              handleClick(`${item.id}`, item)
                            }}
                          >
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                                textTransform: 'Capitalize',
                              }}
                            >
                              {item.nom}
                            </Typography>
                          </BodyTab>
                        )}
                        {(!listeMatching || isAdmin) && (
                          <BodyTab
                            type={type}
                            onClick={() => {
                              setId(item.id)
                              /*handleClick(`${item.nom} ${item.prenom}`);*/
                              handleClick(`${item.id}`, item)
                            }}
                          >
                            {item.prenom}
                          </BodyTab>
                        )}
                        <BodyTab type={type}>
                          {listeMatching && (
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {matchingPost}
                            </Typography>
                          )}
                          {item.candidat?.poste && (
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {item.candidat.poste.titre}
                            </Typography>
                          )}
                        </BodyTab>
                        {!listeMatching && (
                          <>
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              onClick={() => {
                                setId(item.id)
                                /*handleClick(`${item.nom} ${item.prenom}`);*/
                                handleClick(`${item.id}`, item)
                              }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {item.email}
                              </Typography>
                            </BodyTab>
                            {/*  <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              onClick={() => {
                                setId(item.id)
                                handleClick(`${item.id}`, item)
                              }}
                            >
                              {item.userOffres?.length > 0 &&
                                item.userOffres[0] && (
                                  <>
                                    <Typography
                                      sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1',
                                        WebkitBoxOrient: 'vertical',
                                      }}
                                    >
                                      {item.userOffres[0].offre.titre}
                                    </Typography>
                                  </>
                                )}
                            </BodyTab> */}
                            <BodyTab
                              type={type}
                              onClick={() => {
                                setId(item.id)
                                /*handleClick(`${item.nom} ${item.prenom}`);*/
                                handleClick(`${item.id}`, item)
                              }}
                            >
                              {moment(item.date)
                                .tz('Europe/Paris')
                                .format('DD/MM/YYYY') +
                                ' ' +
                                moment(item.date)
                                  .tz('Europe/Paris')
                                  .format('HH:mm')}
                            </BodyTab>
                          </>
                        )}
                        {listeMatching && (
                          <>
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              onClick={() => {
                                setId(item.id)
                                /*handleClick(`${item.nom} ${item.prenom}`);*/
                                handleClick(`${item.id}`, item)
                              }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {unserialize(item.competences) &&
                                  unserialize(item.competences).join(', ')}
                              </Typography>
                            </BodyTab>
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              onClick={() => {
                                setId(item.id)
                                /*handleClick(`${item.nom} ${item.prenom}`);*/
                                handleClick(`${item.id}`, item)
                              }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {unserialize(item.langues) &&
                                  unserialize(item.langues).join(', ')}
                              </Typography>
                            </BodyTab>
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              onClick={() => {
                                setId(item.id)
                                /*handleClick(`${item.nom} ${item.prenom}`);*/
                                handleClick(`${item.id}`, item)
                              }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {parseFloat(item.matching_percentage).toFixed(
                                  2
                                )}{' '}
                                %
                              </Typography>
                            </BodyTab>
                          </>
                        )}
                      </>
                    )}

                    <BodyTab
                      type={type}
                      sx={{
                        padding: '10px',
                        width: listeMatching
                          ? 'auto'
                          : userData.data.role === 'ROLE_ADMIN' ||
                            userData.data.role === 'ROLE_SUPER_ADMIN'
                          ? '70px'
                          : '0',
                      }}
                    >
                      {/* <ButtonIcon
                        onClick={() => {
                          setId(item.id)
                          handleClick(`${item.id}`, item)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon> */}
                      {!listeMatching &&
                        (userData.data.role === 'ROLE_ADMIN' ||
                          userData.data.role === 'ROLE_SUPER_ADMIN') && (
                          <DeleteIcon
                            onClick={(e) => {
                              handleRemove(item.id)
                              //e.target.parentNode.parentNode.parentNode.remove()
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color={lightTheme.palette.error.dark}
                              fontSize={16}
                            />
                          </DeleteIcon>
                        )}
                    </BodyTab>
                    <BlockTab type={type} />
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {!items?.length > 0 && (
          <EmptyText>
            pas {type === 'entreprise' ? " d'entreprises" : 'de candidats'}{' '}
            ici...
          </EmptyText>
        )}
      </TableContainer>

      {/*<AppPagination setProducts={(p) => setProducts(p)}/>*/}
    </>
  )
}

export default ListTable
