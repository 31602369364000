import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { useSelector } from 'react-redux'

const SidebarLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  color: lightTheme.palette.grey.light,
  justifyContent: 'space-between',
  alignItems: 'center',
  listStyle: 'none',
  height: 60,
  textDecoration: 'none',
  fontSize: 14,
  fontFamily: 'Century Gothic Bold',
  lineHeight: '140%',
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
}))

const Title = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

const Icon = styled(Box)({
  width: 25,
  height: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const SidebarLabel = styled('span')(({ theme }) => ({
  marginLeft: '12px',
}))

const DropdownLink = styled(NavLink)({
  height: 60,
  paddingLeft: '10px',
  display: 'flex',
  alignItems: 'center',
  columnGap: '15px',
  textDecoration: 'none',
  color: lightTheme.palette.grey.main,
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',

  '&:hover': {
    color: lightTheme.palette.grey.light,
    cursor: 'pointer',
  },

  '&.active': {
    color: lightTheme.palette.grey.light,
  },
})

const DropdownLabel = styled('span')({})

const Submenu = ({
  item,
  countCandidat,
  countProspect,
  countPartenaire,
  countEsn,
  countEntreprise,
  countCollaborateur,
  countBesoinEncours,
  countBesoinAvenir,
  countBesoinSigner,
  countBesoinPerdu,
}) => {
  const userData = useSelector((state) => state.userAuth.user)
  const navigate = useNavigate()
  const [subnav, setSubnav] = useState(false)

  const [countDemande, setCountDemande] = useState(0)

  const showSubnav = () => setSubnav(!subnav)

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <Title>
          <Icon>{item.icon}</Icon>
          <SidebarLabel
            onClick={() => (subnav ? console.log('ddd') : navigate(item.path))}
          >
            {item.title}
          </SidebarLabel>
        </Title>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((subitem, index) => {
          return (
            <DropdownLink to={subitem.path} key={index}>
              {subitem.icon}
              <DropdownLabel>
                {subitem.title}{' '}
                {subitem.counter &&
                  item.counter === 'candidat' &&
                  subitem.counter === 'client' &&
                  '(' + countCandidat + ')'}
                {subitem.counter &&
                  item.counter === 'candidat' &&
                  subitem.counter === 'prospect' &&
                  '(' + countCollaborateur + ')'}
                {subitem.counter &&
                  item.counter === 'entreprise' &&
                  subitem.counter === 'client' &&
                  '(' + countEntreprise + ')'}
                {subitem.counter &&
                  item.counter === 'entreprise' &&
                  subitem.counter === 'prospect' &&
                  '(' + countProspect + ')'}
                {subitem.counter &&
                  item.counter === 'entreprise' &&
                  subitem.counter === 'partenaire' &&
                  '(' + countPartenaire + ')'}
                {subitem.counter &&
                  item.counter === 'entreprise' &&
                  subitem.counter === 'esn' &&
                  '(' + countEsn + ')'}
                {subitem.counter &&
                  subitem.counter === 'encours' &&
                  '(' + countBesoinEncours + ')'}
                {subitem.counter &&
                  subitem.counter === 'avenir' &&
                  '(' + countBesoinAvenir + ')'}
                {subitem.counter &&
                  subitem.counter === 'signer' &&
                  '(' + countBesoinSigner + ')'}
                {subitem.counter &&
                  subitem.counter === 'perdu' &&
                  '(' + countBesoinPerdu + ')'}
              </DropdownLabel>
            </DropdownLink>
          )
        })}
    </>
  )
}

export default Submenu
